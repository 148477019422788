@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/a5667370d5174cc2-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/28b441eb8873d9a0-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/1ff143f15b714ac1-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/ebb0522188ab3866-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/80003c93ce7b1b70-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/93fba259beb69b37-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/ec174819a957e2dd-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/4e836ac4714700ed-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/2984c2262cf028ba-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/39b3ae3655441fd6-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/9e070720bac6b09b-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__larsseit_cd4cdd';
src: url(/_next/static/media/bb30ccfff37d1d51-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: '__larsseit_Fallback_cd4cdd';src: local("Arial");ascent-override: 104.48%;descent-override: 33.47%;line-gap-override: 0.00%;size-adjust: 98.58%
}.__className_cd4cdd {font-family: '__larsseit_cd4cdd', '__larsseit_Fallback_cd4cdd'
}.__variable_cd4cdd {--font-larsseit: '__larsseit_cd4cdd', '__larsseit_Fallback_cd4cdd'
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/3b6dbe23294c0957-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/df4a37ac54984bf4-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/4d28dfaa49801b8d-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/0e098c3cd17bf3e3-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/5279a5b4c346816f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/da161c0ae542595a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/a271541ae7db69f5-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/1bd66e126e780bd6-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/1173bcf0b182f582-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/d3d0f63e82862b69-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/10e159d6d4678dfa-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__magnatHead_3443bf';
src: url(/_next/static/media/ee72a70f134f55d1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: italic;
}@font-face {font-family: '__magnatHead_Fallback_3443bf';src: local("Arial");ascent-override: 96.70%;descent-override: 25.61%;line-gap-override: 0.00%;size-adjust: 101.14%
}.__className_3443bf {font-family: '__magnatHead_3443bf', '__magnatHead_Fallback_3443bf'
}.__variable_3443bf {--font-magnat-head: '__magnatHead_3443bf', '__magnatHead_Fallback_3443bf'
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/b5a5455e58dd837c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/193831e6df95d440-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/7e02b2f19043e05f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/dddce3d696ae97de-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/135c2f8fd198fb8a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/11622c3f36460aab-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/3be981b3fad5a2ed-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/b6deaf2a28508ac1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/442d4d2758b5da3b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/51188b05c30cec41-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/7d2c1de1969a05d2-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__magnatText_b69a5c';
src: url(/_next/static/media/4b618201be9a9241-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 800;
font-style: italic;
}@font-face {font-family: '__magnatText_Fallback_b69a5c';src: local("Arial");ascent-override: 97.05%;descent-override: 25.60%;line-gap-override: 0.00%;size-adjust: 102.73%
}.__className_b69a5c {font-family: '__magnatText_b69a5c', '__magnatText_Fallback_b69a5c'
}.__variable_b69a5c {--font-magnat-text: '__magnatText_b69a5c', '__magnatText_Fallback_b69a5c'
}

